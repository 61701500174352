import React from 'react'
import { graphql } from "gatsby"
import TextPage from '../templates/textPage'

const About = ({ data, location }) => <TextPage data={data.wordpressPage} location={location} />

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "about"}) {
      title
      slug
      content
    }
}
`
export default About